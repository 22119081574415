import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_LANDINGPAGE, COOKIE_REGION, KEY_SESSION, REFRESHTOKEN_SESSION, TOKEN_SESSION } from 'src/globals';
import { Search } from '../models/search';
import { SessionData, SessionDataAuth } from '../models/session-data';
import { showFeedbackDanger } from './alert-swal';
import { EventHandler } from './handler.event';
import { SsrUtilService } from './ssr-util.service';

export enum PropertyViewType {
  GRID = 0,
  LIST = 1,
}

@Injectable()
export class SessionManager implements CanActivate {
  // private static instance: SessionManager;

  constructor(
    private cookieService: CookieService,
    private ssr: SsrUtilService
    // private _router: Router
  ) {
    // SessionManager.instance = this;
    this.defineSearchFromCookie();
  }

  // Está sendo usado para forçar o singleton ser preenchido no construtor
  canActivate(): boolean {
    // if (SessionManager.isLogged())
    //     return true;
    // else {
    //     this.router.navigate(['/home'], { queryParams: { returnUrl: this.router.url } });
    //     return false;
    // }
    return true;
  }

  getSessionDataAuth(): SessionDataAuth {
    if (this.ssr?.isServer) return null;
    try {
      const jwt = new JwtHelperService();
      let obj = jwt.decodeToken(this.getToken());

      return JSON.parse(JSON.stringify(obj));
    } catch (error) {
      // this._router.navigate(['/auth/logout'], { queryParams: { returnUrl: encodeURIComponent(this._router.url) } });
      return null;
    }
  }

  hasToken(): boolean {
    if (this.ssr?.isServer) return false;
    return this.getToken() != undefined;
  }

  isTokenExpired(): boolean {
    if (this.ssr?.isServer) return false;
    return new JwtHelperService().isTokenExpired(this.getToken());
  }

  getToken(): string {
    if (this.ssr?.isServer) return null;
    return localStorage.getItem(TOKEN_SESSION);
  }

  getRefreshToken(): string {
    if (this.ssr?.isServer) return null;
    return localStorage.getItem(REFRESHTOKEN_SESSION);
  }

  login(token: string, refreshToken: string = null): void {
    if (this.ssr?.isServer) return null;
    localStorage.setItem(TOKEN_SESSION, token);
    if (refreshToken) localStorage.setItem(REFRESHTOKEN_SESSION, refreshToken);
  }

  logout(): void {
    if (this.ssr?.isServer) return null;
    localStorage.removeItem(TOKEN_SESSION);
    localStorage.removeItem(REFRESHTOKEN_SESSION);
  }

  getSessionData(): SessionData {
    try {
      return Object.assign(new SessionData(), JSON.parse(localStorage.getItem(KEY_SESSION)));
    } catch (error) {
      return new SessionData();
      // return null;
    }
  }

  updateSessionData(data: SessionData) {
    try {
      localStorage.setItem(KEY_SESSION, JSON.stringify(data));
    } catch (error) {
      // nada
    }
  }

  getFavorites(): string[] {
    return this.getSessionData()?.favorites || [];
  }

  getDiscards(): string[] {
    return this.getSessionData()?.discards || [];
  }

  toggleFavorite(code: string): boolean {
    let data = this.getSessionData();
    const index = data.favorites?.findIndex(x => x === code);
    const hasFavorite = index > -1;

    if (hasFavorite) {
      // Remove
      data.favorites.splice(index, 1);
    } else {
      // Adiciona
      data.favorites.push(code);
    }

    // Atualiza o SessionData
    data.favorites = data.favorites.filter(x => x); // limpa nulos e ""
    this.updateSessionData(data);
    // Evento
    EventHandler.instance.favoriteChanged();

    return !hasFavorite;
  }

  isFavorite(code: string): boolean {
    return (this.getFavorites()?.findIndex(x => x === code) || -1) > -1;
  }

  totalFavorites(): number {
    return this.getFavorites()?.length || 0;
  }

  toggleDiscarded(code: string): boolean {
    let data = this.getSessionData();
    const index = data.discards?.findIndex(x => x === code);
    const hasDiscarded = index > -1;

    if (hasDiscarded)
      data.discards?.splice(index, 1); // Remove
    else data.discards?.push(code); // Adiciona

    // Atualiza o SessionData
    data.discards = data.discards?.filter(x => x); // limpa nulos e ""
    this.updateSessionData(data);
    // Evento
    EventHandler.instance.discardChanged();

    return !hasDiscarded;
  }

  isDiscarded(code: string): boolean {
    return (this.getDiscards()?.findIndex(x => x === code) || -1) > -1;
  }

  totalDiscards(): number {
    return this.getDiscards()?.length || 0;
  }

  defineViewType(propertyViewType: PropertyViewType) {
    let data = this.getSessionData();
    data.viewType = propertyViewType;
    this.updateSessionData(data);
  }

  /**
   * Define os parâmetros de busca selecionados pelo usuário
   *
   * @param search
   */
  saveCookie(search: Search) {
    let data = this.getSessionData();
    data.search = search;
    this.updateSessionData(data);

    this.cookieService.set(COOKIE_REGION, search.cidade, {
      path: '/',
      expires: 365 * 10,
    });
  }

  /**
   * Pega o obj de busca atual.
   *
   * @returns Obj de busca
   */
  getSearch(): Search {
    let search = this.getSessionData()?.search;
    if (!search) {
      var cidadeSlug = this.cookieService.get(COOKIE_REGION);
      search = new Search();
      search.defineCity(cidadeSlug);
    }

    return new Search(search);
  }

  /**
   * Limpa a busca atual e cria uma nova.
   *
   * @returns Nova busca
   */
  clearSearch(cidadeSlug: string = undefined): Search {
    const s = this.getSearch();
    if (cidadeSlug) s.cidade = cidadeSlug;
    const ns = new Search();
    ns.defineCity(s.cidade);
    ns.status = s.status ? s.status : '0';
    this.saveCookie(ns);
    return ns;
  }

  getWhatsappNumber(search: Search): string {
    let phone = '5551991106172';
    if (search?.cidade == 'sao-paulo') phone = '5511976825868';
    else if (search?.status == '0') phone = '5551996360961';
    return phone;
  }

  getPhoneNumber(search: Search): string {
    // mesmo telefone venda/aluguel em POA
    let phone = '555132762040';
    if (search?.cidade == 'sao-paulo') phone = '5511976825868';
    return phone;
  }

  isRegionDefined(): boolean {
    if (this.ssr?.isServer) return false;
    return this.cookieService?.check(COOKIE_REGION) ?? false;
  }

  regionDefined(cidadeSlug: string, dispatchEvent: boolean = false) {
    var search = this.getSearch();
    // limpa a busca definindo a região
    search = this.clearSearch(cidadeSlug);
    // no setSearch ele define a região
    this.saveCookie(search);

    // dispara eventos
    if (dispatchEvent) EventHandler.builder().searchChanged(search);
  }

  defineSearchFromCookie(): Search {
    if (!this.isRegionDefined()) return;
    var search = this.getSearch();
    if (search) {
      var cidadeSlug = this.cookieService.get(COOKIE_REGION);
      if (cidadeSlug) search.defineCity(cidadeSlug);
      this.saveCookie(search);
    }
    return search;
  }

  defineLandingPage(loc: Location) {
    var landingCookie = this.cookieService.get(COOKIE_LANDINGPAGE);
    if (!landingCookie)
      this.cookieService.set(COOKIE_LANDINGPAGE, loc.href, {
        path: '/',
        expires: 365 * 10,
      });
  }

  /**
   *
   * @param error
   * @param errorMessage
   */
  static handleHttpError(error: HttpErrorResponse, errorMessage: string = 'Falha ao consultar ou enviar dados.') {
    try {
      if (error.status >= 600) showFeedbackDanger('Atenção', error.error?.mensagem);
      else showFeedbackDanger('Atenção', errorMessage);
    } catch {
      // ignora pois não dá pra pegar o ssr aqui
      // portanto, pode ser um erro que tentou abrir a janela e estava no servidor
    }
  }

  /**
   * Na lib da isul este método já existe!
   * @param err
   * @param errorMessage
   * @returns
   */
  static getHttpErrorMessage(
    err: HttpErrorResponse,
    errorMessage: string = 'Não foi possível consultar ou enviar dados.'
  ): string {
    if (err.status >= 600 && err.error) return err.error.message;
    else if (err.status != 401 && err.status != 403) return errorMessage;
  }

  setCorretorSlug(corretorSlug: string): void {
    if (this.ssr?.isServer) return;
    let sd = this.getSessionData();
    sd.brokerSlug = corretorSlug;
    this.updateSessionData(sd);
  }

  getCorretorSlug(): string | undefined {
    if (this.ssr?.isServer) return null;
    let sd = this.getSessionData();
    return sd?.brokerSlug;
  }
}
