import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { Banner } from 'src/app/shared/models/banner';
import { ImovelLancamento } from 'src/app/shared/models/imovel-lancamento';
import { Search } from 'src/app/shared/models/search';
import { BannerService } from 'src/app/shared/services/banner.service';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { PAGE_TITLE } from 'src/globals';

@Component({
  selector: 'app-new-releases-page',
  templateUrl: './new-releases-page.component.html',
  styleUrls: ['./new-releases-page.component.scss'],
})
export class NewReleasesPageComponent implements OnInit {
  banner$: Observable<Banner>;
  releases$: Observable<ImovelLancamento[]>;
  search: Search = new Search();

  constructor(
    private titleService: Title,
    private bannerService: BannerService,
    private imovelService: ImovelService,
    private router: Router,
    private route: ActivatedRoute,
    private sm: SessionManager
  ) {
    this.route.params.subscribe(params => {
      const { cidade } = params;
      if (cidade) {
        this.sm.regionDefined(cidade);

        // Observa as alterações nos params
        this.route.queryParams.subscribe(params => {
          this.search.fromQuerySearch(params);
          this.doSearch();
        });
        // Observa as alterações na rota
        this.route.params.subscribe(params => {
          const { status, cidade } = params;
          if (status) this.search.status = status === 'venda' ? '0' : '1';
          if (cidade) this.search.cidade = cidade;
          this.doSearch();
        });
      } else {
        // define pelo cookie e retorna
        this.search = this.sm.defineSearchFromCookie();
        // muda url para auxiliar o gatilho dos chats
        if (this.search && this.search.cidade) this.router.navigateByUrl(`/imoveis/lancamentos/${this.search.cidade}`);
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Lançamentos - ${PAGE_TITLE}`);

    this.banner$ = this.bannerService.getLancamento(undefined).pipe(
      catchError(error => {
        console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar banner.'));
        return of(null);
      }),
      shareReplay(1)
    );
  }

  onChangeRoute(s: Search = this.search) {
    this.search = s;
    // monta params
    const params = this.search?.toQuerySearch();

    // muda url
    this.router.navigate(['/imoveis/lancamentos/', this.search?.cidade], {
      queryParams: params,
    });
  }

  doSearch() {
    this.releases$ = this.imovelService.getEmpreendimentosEmLancamento(this.search, false).pipe(
      tap(x => {
        // this.cidadeNome = SessionManager.getSessionData().search.cidadeNome;
        if (this.search.cidadeNome)
          this.titleService.setTitle(`Lançamentos em ${this.search.cidadeNome} - ${PAGE_TITLE}`);
      }),
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os lançamentos');
        return of([]);
      })
    );
  }
}
