<div class="i-page-top">
  <div class="container">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-0 text-center text-sm-left">
        <a href="#" routerLink="/home" aria-label="Private"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>
<div class="i-page-content">
  <!--Call-Text-->
  <app-banner-incorporadora [isBanner]="true"></app-banner-incorporadora>
  <!-- -->
  <div class="i-favorite container py-5">
    <div class="row text-center pt-5">
      <h1 class="i-title mx-auto">LANÇAMENTOS DE IMÓVEIS</h1>
    </div>
    <!-- BUSCA -->
    <div class="row justify-content-between py-5">
      <app-form-busca
        [(search)]="search"
        (changeSearch)="onChangeRoute($event)"
        [enableSticky]="true"
        [enableAdvanced]="false"></app-form-busca>
    </div>
    <!-- -->
    <ng-container *ngIf="releases$ | async as releases">
      <ng-container *ngIf="releases?.length > 0; else empty">
        <div class="row row-cols-1 row-cols-md-3 no-gutters">
          <div *ngFor="let release of releases" class="col p-4">
            <app-summary [release]="release"></app-summary>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #empty>
      <div class="col i-text text-center my-3 py-5">
        <!-- SEM REGISTROS -->
        <h2 class="mb-2">Não há imóveis em lançamento nesta cidade.</h2>
        Vá para busca e encontre o imóvei ideal para você.

        <div class="mx-auto col-12 col-lg-8">
          <div class="d-flex flex-column flex-md-row justify-content-around mt-5 mb-5">
            <a href="#" routerLink="/imoveis/lancamentos/porto-alegre" class="i-btn i-btn-inverted"
              >Lançamentos em POA</a
            >
            <a href="#" routerLink="/imoveis/lancamentos/sao-paulo" class="i-btn i-btn-inverted">Lançamentos em SP</a>
          </div>
        </div>

        <div class="d-block mt-5">
          <a href="#" routerLink="/imoveis/status/venda" class="i-btn">Buscar imóveis</a>
        </div>
      </div>
    </ng-template>
  </div>
</div>
