import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { DefaultLayoutComponent } from './layout/default/default.component';
import { SessionManager } from './shared/util/session-manager';
import { CorretorLayoutComponent } from './layout/corretor/corretor.component';

const routes: Routes = [
  // ------------------------------------------
  // website aberto, com layout padrão
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [SessionManager],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'home/cidade/:cidade', component: HomeComponent },
      { path: '', loadChildren: () => import('./modules/property/property.module').then(m => m.PropertyModule) },
      {
        path: '',
        loadChildren: () => import('./modules/new-releases/new-releases.module').then(m => m.NewReleasesModule),
      },
      { path: '', loadChildren: () => import('./modules/page/page.module').then(m => m.PageModule) },
      { path: '', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
      { path: '', loadChildren: () => import('./modules/career/career.module').then(m => m.CareerModule) },
      { path: '', loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule) },
      { path: '', loadChildren: () => import('./modules/compare/compare.module').then(m => m.CompareModule) },
      {
        path: '',
        loadChildren: () => import('./modules/new-releases/new-releases.module').then(m => m.NewReleasesModule),
      },
      { path: '', loadChildren: () => import('./modules/exclusive/exclusive.module').then(m => m.ExclusiveModule) },
      { path: '', loadChildren: () => import('./modules/bombando/bombando.module').then(m => m.BombandoModule) },
      { path: '', loadChildren: () => import('./modules/campaign/campaign.module').then(m => m.CampaignModule) },
      { path: '', loadChildren: () => import('./modules/agro/agro.module').then(m => m.AgroModule) },
      { path: '', loadChildren: () => import('./modules/corporate/corporate.module').then(m => m.CorporateModule) },
      { path: '', loadChildren: () => import('./modules/bola-da-vez/bola-da-vez.module').then(m => m.BolaDaVezModule) },
      {
        path: '',
        loadChildren: () => import('./modules/incorporadora/incorporadora.module').then(m => m.IncorporadoraModule),
      },
    ],
  },
  // ------------------------------------------
  // hotsite do corretor
  {
    path: '',
    component: CorretorLayoutComponent,
    children: [
      {
        path: 'corretor',
        loadChildren: () => import('./modules/corretor/corretor.module').then(m => m.CorretorModule),
      },
    ],
  },
  // ------------------------------------------
  // landings page, sem rodapé ou outros estilos do website principal
  // {
  //     path: '', component: LandingLayoutComponent, children: [
  //         { path: 'melnickeven', loadChildren: () => import('./modules/landings/melnick/melnick.module').then(m => m.MelnickModule) },
  //     ]
  // },
  // ------------------------------------------
  // qualquer caminho, vai pra home
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
