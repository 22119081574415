<div class="i-text d-flex flex-row">
  <a routerLink="/home" class="text-reset i-text" title="Home">Home</a>
  <ng-container *ngIf="status !== null">
    <p class="mx-1">></p>
    <a
      [routerLink]="['/imoveis/status', status === Status.Sell ? 'venda' : 'alugar', 'cidade', cidadeSlug]"
      class="text-reset i-text"
      [title]="status === Status.Sell ? 'Comprar' : 'Alugar'"
      >{{ status === Status.Sell ? 'Comprar' : 'Alugar' }}</a
    >
    <p class="mx-1">></p>
    <a
      [routerLink]="['/imoveis/status', status === Status.Sell ? 'venda' : 'alugar', 'cidade', cidadeSlug]"
      class="text-reset i-text"
      [title]="(status === Status.Sell ? 'Comprar' : 'Alugar') + ' em ' + cidade"
      >{{ cidade }}</a
    >
    <p *ngIf="bairro" class="mx-1">></p>
    <a
      *ngIf="bairro"
      [routerLink]="['/imoveis/status', status === Status.Sell ? 'venda' : 'alugar', 'cidade', cidadeSlug]"
      [queryParams]="{ p: 1, b: bairroSlug }"
      class="text-reset i-text"
      title="{{ status === Status.Sell ? 'Comprar' : 'Alugar' }} em {{ cidade }} {{
        bairro | preposition: 'na' : 'no'
      }} {{ bairro }}"
      >Bairro {{ bairro }}</a
    >
  </ng-container>
</div>
