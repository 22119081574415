import { appendArray, stripSpecialChars, thisCityIsFromSP, toStr } from 'src/globals';
import { BoxType } from '../components/box/box.component';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';

export enum SearchOrder {
  MenorArea = 'menor-area',
  MaiorArea = 'maior-area',
  MenorValor = 'menor-valor',
  MaiorValor = 'maior-valor',
  Recencia = 'recencia',
  Destaque = 'destaque',
}

export class Search {
  pag: number;
  codigo: string;
  corretor: string;
  private _cidade: string; // ver prop
  cidadeNome: string; // não é usado na busca
  private _status: string; // ver prop
  categorias: string[];
  bairros: string[];
  construtoras: string[];
  empreendimentos: string[];
  dormitorios: number[];
  suites: number[];
  vagas: number[];
  areaMin?: number;
  areaMax?: number;
  valorMin?: number;
  valorMax?: number;
  //
  desconto: string[];
  permuta: string[];
  exclusivo: string[];
  destaque: string[];
  lancamento: string[];
  agro: string[];
  corporate: string[];
  bolaVez: string[];
  incorporadora: string[];
  //
  construcao: string[];
  novo: string[];
  usado: string[];
  mobiliado: string[];
  reforma: string[];
  //
  ordem: SearchOrder | string;
  //
  boxType: BoxType = BoxType.Grid;

  private _priceRange: number[][] = [];

  constructor(values?: any) {
    this.pag = 1;
    this.status = '0';
    this.cidade = 'porto-alegre';
    this.cidadeNome = 'Porto Alegre';
    this.ordem = SearchOrder.Destaque; // "destaque";
    this.categorias = [];
    this.bairros = [];
    this.construtoras = [];
    this.empreendimentos = [];
    this.dormitorios = [];
    this.suites = [];
    this.vagas = [];
    //
    this.desconto = [];
    this.permuta = [];
    this.exclusivo = [];
    this.destaque = [];
    this.lancamento = [];
    this.agro = [];
    this.corporate = [];
    this.bolaVez = [];
    this.incorporadora = [];
    //
    this.construcao = [];
    this.novo = [];
    this.usado = [];
    this.mobiliado = [];
    this.reforma = [];

    if (values) Object.assign(this, values);
  }

  defineCity(cidadeSlug: string) {
    this.cidade = cidadeSlug;
    this.cidadeNome = cidadeSlug == 'sao-paulo' ? 'São Paulo' : 'Porto Alegre';
  }

  public get cidade(): string {
    return this._cidade;
  }

  public set cidade(v: string) {
    this._cidade = v;
    this._priceRange = [];
  }

  public get status(): string {
    return this._status;
  }

  public set status(v: string) {
    this._status = v;
    this._priceRange = [];
  }

  public get isSell(): boolean {
    return this.status == '0';
  }

  public get isRent(): boolean {
    return this.status == '1';
  }

  public get dormitoriosEx(): string[] {
    return this.dormitorios?.map(x => `${x}`) ?? [];
  }
  public get suitesEx(): string[] {
    return this.suites?.map(x => `${x}`) ?? [];
  }
  public get vagasEx(): string[] {
    return this.vagas?.map(x => `${x}`) ?? [];
  }

  public get statusEx(): string {
    return this.status == '0' ? 'venda' : 'alugar';
  }

  fromQuerySearch(params: Params) {
    const { b, co, em, c, v1, v2, a1, a2, d, s, v, di, p, cs, no, ex, dt, bv, inc, mla, la, us, mo, re, cod, pag, o } =
      params;

    if (b) this.bairros = b.split(',');
    if (co) this.construtoras = co.split(',');
    if (em) this.empreendimentos = em.split(',');
    if (c) this.categorias = c.split(',');
    if (v1) this.valorMin = stripSpecialChars(v1);
    if (v2) this.valorMax = stripSpecialChars(v2);
    if (a1) this.areaMin = a1;
    if (a2) this.areaMax = a2;
    if (d) this.dormitorios = d.split(',')?.map(x => parseInt(x));
    if (s) this.suites = s.split(',')?.map(x => parseInt(x));
    if (v) this.vagas = v.split(',')?.map(x => parseInt(x));
    if (di) this.desconto = di.split(',');
    if (p) this.permuta = p.split(',');
    if (cs)
      // this.construcao = cs.split(',');
      this.lancamento = this.construcao = cs.split(',');
    if (no) this.novo = no.split(',');
    // if (ex) this.exclusivo = ex.split(',');
    if (dt) this.destaque = dt.split(',');
    if (bv) this.bolaVez = bv.split(',');
    if (inc) this.incorporadora = inc.split(',');
    if (la)
      // this.lancamento = la.split(',');
      this.lancamento = this.construcao = la.split(',');
    // se veio do menu lançamento, muda para modo lista e não grade
    if (mla) this.boxType = BoxType.List;
    if (us) this.usado = us.split(',');
    if (mo) this.mobiliado = mo.split(',');
    if (re) this.reforma = mo.split(',');
    if (cod) this.codigo = cod;
    if (pag) this.pag = pag;
    if (o) this.ordem = o || 'destaque';
  }

  toQuerySearch() {
    const params: any = {};
    if (this.bairros?.length > 0) params.b = this.bairros?.toString();
    if (this.construtoras?.length > 0) params.co = this.construtoras?.toString();
    if (this.empreendimentos?.length > 0) params.em = this.empreendimentos?.toString();
    if (this.categorias?.length > 0) params.c = this.categorias?.toString();
    if (this.valorMin) params.v1 = this.valorMin;
    if (this.valorMax) params.v2 = this.valorMax;
    if (this.areaMin) params.a1 = this.areaMin;
    if (this.areaMax) params.a2 = this.areaMax;
    if (this.dormitorios?.length > 0) params.d = this.dormitorios?.toString();
    if (this.suites?.length > 0) params.s = this.suites?.toString();
    if (this.vagas?.length > 0) params.v = this.vagas?.toString();
    if (this.desconto?.length > 0) params.di = this.desconto?.toString();
    if (this.permuta?.length > 0) params.p = this.permuta?.toString();
    if (this.construcao?.length > 0)
      // params.cs = this.construcao?.toString();
      params.cs = params.la = this.construcao?.toString();
    if (this.novo?.length > 0) params.no = this.novo?.toString();
    if (this.destaque?.length > 0) params.dt = this.destaque?.toString();
    if (this.lancamento?.length > 0)
      // params.la = this.lancamento?.toString();
      params.cs = params.la = this.lancamento?.toString();
    // if (this.exclusivo?.length > 0) params.ex = this.exclusivo?.toString();
    // if (this.agro?.length > 0) params.agro = this.agro?.toString();
    // if (this.corporate?.length > 0) params.corporate = this.corporate?.toString();
    if (this.bolaVez?.length > 0) params.bv = this.bolaVez?.toString();
    if (this.incorporadora?.length > 0) params.inc = this.incorporadora?.toString();
    // -----
    if (this.usado?.length > 0) params.us = this.usado?.toString();
    if (this.mobiliado?.length > 0) params.mo = this.mobiliado?.toString();
    if (this.reforma?.length > 0) params.re = this.reforma?.toString();
    if (this.codigo) params.cod = this.codigo;
    if (this.ordem) params.o = this.ordem;
    params.pag = this.pag;

    return params;
  }

  toParams() {
    let params = new HttpParams()
      .set('page', toStr(this.pag ?? 1))
      .set('codigo', toStr(this.codigo))
      .set('cidade', toStr(this.cidade))
      .set('corretor', toStr(this.corretor))
      .set('status', toStr(this.status))
      .set('areaMin', toStr(this.areaMin))
      .set('areaMax', toStr(this.areaMax))
      .set('valorMin', toStr(this.valorMin))
      .set('valorMax', toStr(this.valorMax))
      .set('desconto', toStr(this.desconto))
      .set('permuta', toStr(this.permuta))
      .set('construcao', toStr(this.construcao || this.lancamento))
      .set('novo', toStr(this.novo))
      .set('destaque', toStr(this.destaque))
      .set('lancamento', toStr(this.construcao || this.lancamento))
      .set('exclusivo', toStr(this.exclusivo))
      .set('agro', toStr(this.agro))
      .set('corporate', toStr(this.corporate))
      .set('bolaVez', toStr(this.bolaVez))
      .set('incorporadora', toStr(this.incorporadora))
      .set('usado', toStr(this.usado))
      .set('mobiliado', toStr(this.mobiliado))
      .set('reforma', toStr(this.reforma))
      .set('orderBy', toStr(this.ordem));

    params = appendArray(params, 'categorias', this.categorias);
    params = appendArray(params, 'bairros', this.bairros);
    params = appendArray(params, 'construtoras', this.construtoras);
    params = appendArray(params, 'empreendimentos', this.empreendimentos);
    params = appendArray(params, 'dormitorios', this.dormitorios);
    params = appendArray(params, 'suites', this.suites);
    params = appendArray(params, 'vagas', this.vagas);

    return params;
  }

  /**
   * Valida se existe um filtro na pesquisa
   */
  get hasFilter(): boolean {
    return (
      !!this.codigo ||
      this.categorias?.length > 0 ||
      this.bairros?.length > 0 ||
      this.construtoras?.length > 0 ||
      this.empreendimentos?.length > 0 ||
      this.dormitorios?.length > 0 ||
      this.suites?.length > 0 ||
      this.vagas?.length > 0 ||
      this.areaMin > 0 ||
      this.areaMax > 0 ||
      this.valorMin > 0 ||
      this.valorMax > 0 ||
      this.desconto?.length > 0 ||
      this.permuta?.length > 0 ||
      this.destaque?.length > 0 ||
      this.lancamento?.length > 0 ||
      // this.exclusivo?.length > 0 ||
      // this.agro?.length > 0 ||
      // this.corporate?.length > 0 ||
      this.bolaVez?.length > 0 ||
      this.incorporadora?.length > 0 ||
      this.construcao?.length > 0 ||
      this.novo?.length > 0 ||
      this.usado?.length > 0 ||
      this.mobiliado?.length > 0 ||
      this.reforma?.length > 0
    );
  }

  public get isSP(): boolean {
    return thisCityIsFromSP(this.cidade);
  }

  public get prices(): number[] {
    if (this.isSell) {
      // COMPRAR - faixas de venda
      if (this.isSP) return [100000, 200000, 500000, 1000000, 1500000, 3000000, 5000000, 10000000, 15000000, 20000000];
      else return [100000, 200000, 400000, 700000, 1000000, 1500000, 2000000, 3000000, 5000000];
    } else {
      // ALUGAR - faixas de locação
      return [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 20000, 25000,
        30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000,
      ];
    }
  }

  public get priceRanges(): number[][] {
    if ((this._priceRange?.length || 0) <= 0) {
      let retval: number[][] = [];

      const lst = this.prices;
      for (let i = 0; i < lst.length - 1; i++) {
        const val1 = lst[i];
        const val2 = lst[i + 1];
        retval.push([val1, val2]);
      }

      retval.push([lst[lst.length - 1], null]);

      this._priceRange = retval;
    }
    return this._priceRange;
  }
}
