import { AfterContentInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Imovel, Status } from 'src/app/shared/models/imovel';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { formatPhone, paramToUrl } from 'src/globals';
import { SsrUtilService } from '../../util/ssr-util.service';
import { Corretor } from '../../models/corretor';
import { SessionManager } from '../../util/session-manager';

declare let $: any; // jquery

export enum BoxType {
  Grid,
  List,
  Detail,
  Expanded,
}

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit, AfterContentInit {
  formatPhone = formatPhone;
  paramToUrl = paramToUrl;
  BoxType = BoxType;
  Status = Status;
  @Input() property = new Imovel();
  corretor = new Corretor();
  @Input() boxType: BoxType = BoxType.Grid;
  @Input() targetBlank: boolean = false;
  @Input() tags: string[];
  @Input() showBroker: boolean = true;
  @Input() showMoreLessButton: boolean = true;
  @Input() numberOfPhotos: number = 5;
  descriptionResume: string;
  scrollEvent = new Event('scroll');

  constructor(
    private ssr: SsrUtilService,
    private el: ElementRef,
    private sm: SessionManager,
    private ga: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (!this.property) return;

    this.corretor = this.property.getCorretor(this.sm);
    this.descriptionResume = this.property?.descricaoWeb;
    const limitChar = 200;
    if (this.property?.descricaoWeb?.length > limitChar)
      this.descriptionResume = `${this.property.descricaoWeb.substring(0, limitChar)}...`;
  }

  ngAfterContentInit(): void {
    const _this = this;
    if (this.ssr?.isBrowser)
      $(function () {
        $(_this.el.nativeElement).find('[data-toggle="tooltip"]').tooltip(); // tem que ser jquery
      });
  }

  onToggleListDetail(type: BoxType) {
    this.boxType = type;

    if (this.ssr?.isBrowser)
      setTimeout(() => {
        const _this = this;
        $(function () {
          $(_this.el.nativeElement).find('[data-toggle="tooltip"]').tooltip(); // tem que ser jquery
          // ng-lazyload-image
          this.ssr?.getWindow().dispatchEvent(_this.scrollEvent);
        });
      }, 500);
  }

  onGaEvent(action: string, category: string, label: string, value: number = undefined) {
    this.ga.trackEvent(action, category, label, value);
  }

  check(tag: string): boolean {
    return (
      this.property?.caracteristicas?.some(x => x.nome == tag) ||
      this.property?.infraestruturas?.some(x => x.nome == tag)
    );
  }
}
