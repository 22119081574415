<div class="i-box-carousel" [class.i-discarded]="isDiscarded">
  <ngx-hm-carousel
    *ngIf="ssr.isBrowser"
    #carousel
    [(ngModel)]="currentIndex"
    (ngModelChange)="onChangeIndex()"
    [show-num]="1"
    [infinite]="true"
    [drag-many]="true"
    [disable-drag]="disableDrag"
    [mouse-enable]="true"
    [aniTime]="200"
    [data]="property?.fotos"
    class="carousel">
    <section ngx-hm-carousel-container class="content">
      <article
        class="item cursor-pointer fade-animation"
        ngx-hm-carousel-item
        *ngFor="let photo of property?.fotos | slice : 0 : numberOfPhotos; let i = index">
        <a
          [routerLink]="property?.internalUrl"
          attr.title="{{ property?.titulo }} - Foto {{ i + 1 }}"
          [target]="targetBlank ? '_blank' : null">
          <div
            class="i-box-img img"
            defaultImage="assets/img/img_loading.gif"
            [lazyLoad]="i === 0 ? photo?.fotoUrl : photo?.fotoPequenaUrl"></div>
        </a>
      </article>
      <ng-template #infiniteContainer></ng-template>
    </section>

    <!-- only using in infinite mode or autoplay mode, that will render with-->
    <ng-template #carouselContent let-photo let-i="index">
      <article class="item cursor-pointer fade-animation">
        <a
          [routerLink]="property?.internalUrl"
          attr.title="{{ property?.titulo }} - Foto {{ i + 1 }}"
          [target]="targetBlank ? '_blank' : null">
          <div class="i-box-img img" defaultImage="assets/img/img_loading.gif" [lazyLoad]="photo?.fotoPequenaUrl"></div>
        </a>
      </article>
    </ng-template>

    <ng-template *ngIf="property?.fotos?.length > 1" #carouselPrev>
      <div class="i-box-nav click-area">
        <picture>
          <source type="image/webp" srcset="assets/img/btn_nav_esquerda.webp" />
          <img
            class="material-icons"
            src="assets/img/btn_nav_esquerda.png"
            width="17px"
            height="31px"
            alt="Nav.esquerda" />
        </picture>
      </div>
    </ng-template>
    <ng-template *ngIf="property?.fotos?.length > 1" #carouselNext>
      <div class="i-box-nav click-area">
        <picture>
          <source type="image/webp" srcset="assets/img/btn_nav_direita.webp" />
          <img
            class="material-icons"
            src="assets/img/btn_nav_direita.png"
            width="17px"
            height="31px"
            alt="Nav.direita" />
        </picture>
      </div>
    </ng-template>
  </ngx-hm-carousel>
  <!--Ribbons-->
  <div
    *ngIf="
      !isDiscarded &&
      (property?.paraReforma ||
        property?.exclusivo ||
        property?.lancamento ||
        property?.destaqueWeb ||
        property?.superDestaqueWeb ||
        property?.agro ||
        property?.locacaoSobMedida)
    "
    class="ribbon">
    <span
      [ngClass]="
        property?.agro
          ? 'i-agro'
          : property?.locacaoSobMedida
          ? 'i-sob-medida'
          : property?.paraReforma
          ? 'i-reforma'
          : property?.exclusivo
          ? 'i-exclusivo'
          : property?.incorp
          ? 'i-incorp'
          : property?.lancamento
          ? 'i-lancamento'
          : 'i-destaque'
      ">
      {{
        property?.agro
          ? '+AGRO'
          : property?.locacaoSobMedida
          ? 'SOB MEDIDA'
          : property?.paraReforma
          ? 'P/REFORMA'
          : property?.exclusivo
          ? 'EXCLUSIVO'
          : property?.lancamento
          ? 'LANÇAMENTO'
          : 'DESTAQUE'
      }}</span
    >
  </div>
  <!--Etiqueta Laranja
    <div *ngIf="property?.etiquetaLaranja" style="bottom: 1rem; left: 1rem; position: absolute;">
        <img src="assets/img/logo-etiqueta-laranja.png" class="img-fluid" title="Etiqueta Laranja"
            alt="Etiqueta Laranja" style="height: 1.8rem;" />
    </div>
    -->
  <!-- Buttons -->
  <div *ngIf="property?.id" class="i-buttons p-2 w-100 d-flex justify-content-end">
    <!--Permuta-->
    <button
      *ngIf="property?.aceitaPermuta"
      class="p-2 btn btn-link"
      data-toggle="tooltip"
      title="ESTE IMÓVEL ACEITA PERMUTA"
      aria-label="ESTE IMÓVEL ACEITA PERMUTA"
      data-placement="bottom">
      <i class="iconify" data-icon="mdi-swap-horizontal"></i>
    </button>
    <!--Descartado-->
    <button
      class="p-2 btn btn-link"
      data-toggle="tooltip"
      [title]="isDiscarded ? 'REMOVER DOS DESCARTADOS' : 'ADICIONAR AOS DESCARTADOS'"
      [attr.aria-label]="isDiscarded ? 'REMOVER DOS DESCARTADOS' : 'ADICIONAR AOS DESCARTADOS'"
      data-placement="bottom"
      (click)="onChangeDiscarded()">
      <i *ngIf="!isDiscarded"><i class="iconify" data-icon="mdi-trash-can-outline"></i></i>
      <i *ngIf="isDiscarded"><i class="iconify" data-icon="mdi-trash-can"></i></i>
    </button>
    <!--Favorito-->
    <button
      class="p-2 btn btn-link"
      data-toggle="tooltip"
      [title]="isFavorite ? 'REMOVER DOS FAVORITOS' : 'ADICIONAR AOS FAVORITOS'"
      [attr.aria-label]="isFavorite ? 'REMOVER DOS FAVORITOS' : 'ADICIONAR AOS FAVORITOS'"
      data-placement="bottom"
      (click)="onChangeFavorite()">
      <i *ngIf="!isFavorite"><i class="iconify" data-icon="mdi-heart-outline"></i></i>
      <i *ngIf="isFavorite"><i class="iconify" data-icon="mdi-heart"></i></i>
    </button>
  </div>
</div>
