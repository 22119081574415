import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { ImovelLancamento } from 'src/app/shared/models/imovel-lancamento';
import { Incorporadora } from 'src/app/shared/models/incorporadora';
import { Search } from 'src/app/shared/models/search';
import { IncorporadoraService } from 'src/app/shared/services/incorporadora.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { PAGE_TITLE } from 'src/globals';

@Component({
  selector: 'app-incorporadora',
  templateUrl: './incorporadora.component.html',
  styleUrls: ['./incorporadora.component.scss'],
})
export class IncorporadoraComponent implements OnInit {
  incorporadora$: Observable<Incorporadora>;
  releases$: Observable<ImovelLancamento[]>;
  search: Search = new Search();
  constrotora: string;
  construtoraSlug: string;

  constructor(private titleService: Title, private incorpService: IncorporadoraService, private route: ActivatedRoute) {
    //
    this.route.params.subscribe(params => {
      const { construtora } = params;
      this.construtoraSlug = construtora;
      this.doSearch();
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Imóveis - ${PAGE_TITLE}`);

    this.loadData();
  }

  loadData() {
    // incorporadora
    this.incorporadora$ = this.incorpService.getInfo(this.construtoraSlug).pipe(
      map(info => {
        info.imagemFundoUrl = info.imagemFundoUrl ?? '../assets/img/incorporadora/banner-incorporadora.jpg';
        return info;
      }),
      catchError(error => {
        console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar incorporadora.'));
        // this.router.navigateByUrl('/home');
        return of(null);
      }),
      shareReplay(1)
    );
  }

  doSearch() {
    this.search.incorporadora = ['true'];
    this.search.construtoras = [this.construtoraSlug];
    this.releases$ = this.incorpService.getEmpreendimentos(this.construtoraSlug, this.search).pipe(
      tap(x => {
        if (x?.[0]?.incorporadoraNome) {
          this.constrotora = x[0].incorporadoraNome;
          this.titleService.setTitle(`Imóveis ${x[0].incorporadoraNome?.toUpperCase()} - ${PAGE_TITLE}`);
        }
      }),
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os imóveis');
        return of({} as ImovelLancamento[]);
      }),
      shareReplay(1)
    );
  }
}
