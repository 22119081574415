export class ImovelFoto {
  id: string;
  tipo: number;
  descricao: string;
  fotoUrl: string;
  fotoPequenaUrl: string;

  constructor(values?: any) {
    this.fotoUrl = 'assets/img/img_loading.gif';
    this.fotoPequenaUrl = 'assets/img/img_loading.gif';

    if (values) Object.assign(this, values);
  }
}
