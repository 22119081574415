<!--Box-Grid-->
<ng-container *ngIf="boxType === BoxType.Grid">
  <div class="i-box-grid">
    <app-box-carousel
      [property]="property"
      [disableDrag]="true"
      [targetBlank]="targetBlank"
      [numberOfPhotos]="numberOfPhotos"></app-box-carousel>
    <!--Description-->
    <div class="d-flex justify-content-between pt-2">
      <div class="i-text">{{ property?.categoria | uppercase }}</div>
      <div class="i-text-sm">COD {{ property?.codigo }}</div>
    </div>

    <div class="i-subtitle d-flex justify-content-between">
      {{ property?.bairro | uppercase }}
      <a
        [routerLink]="property?.internalUrl"
        data-toggle="tooltip"
        title="IMÓVEL VISUALIZADO"
        aria-label="IMÓVEL VISUALIZADO">
        <i class="iconify" data-icon="mdi-eye-outline"></i>
      </a>
    </div>
    <div class="i-data d-flex justify-content-between align-items-end pt-2">
      <div class="i-box-sm d-flex mb-1 pt-1">
        <div *ngIf="property?.agro" class="i-text-sm text-center pr-1">
          {{ property.categoria }}
        </div>
        <div *ngIf="property?.quartosEx > 0" class="i-text-sm text-center pr-1">
          {{ property.quartosEx || 0 }}<br />{{ property.quartosLegendaEx }}
        </div>
        <div *ngIf="property?.area > 0" class="i-text-sm text-center px-1">
          {{ property?.area }}<br />
          <ng-container *ngIf="!property?.agro">m<sup>2</sup></ng-container>
          <ng-container *ngIf="property?.agro">ha</ng-container>
        </div>
        <div *ngIf="property?.vagas > 0" class="i-text-sm text-center pl-1">
          {{ property?.vagas || 0 }}<br />VAGA{{ property?.vagas === 1 ? '' : 'S' }}
        </div>
      </div>
      <div class="i-value pt-1">
        <div *ngIf="property?.podeMostrarValorAnterior || false" class="i-text text-right d-block">
          <s>{{ property?.valorAnterior | R$: 0 }}</s>
        </div>
        <div class="i-subtitle">
          <span
            *ngIf="property?.statusEx === Status.Rent"
            data-toggle="tooltip"
            data-html="true"
            [title]="property?.valorAluguelDetalhado">
            <i class="iconify" data-icon="mdi-information-outline"></i>
          </span>
          <ng-container *ngIf="property?.valor > 0; else semValor">
            {{ property?.valor | R$: 0 }}
          </ng-container>
          <ng-template #semValor> Sob consulta </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!--Box-List-->
<ng-container *ngIf="boxType === BoxType.List">
  <div class="i-box-list row pb-3">
    <div class="col-12 col-md-9 col-lg-4">
      <app-box-carousel [property]="property" [targetBlank]="targetBlank" [numberOfPhotos]="numberOfPhotos">
      </app-box-carousel>
    </div>
    <div class="col-12 col-md-3 col-lg-1 my-auto">
      <div class="d-flex flex-row flex-md-column justify-content-center justify-content-lg-start py-3">
        <div class="i-text-sm mr-auto mt-auto py-2">COD {{ property?.codigo }}</div>
        <div *ngIf="property?.agro" class="i-text-sm mr-auto py-2">
          {{ property.categoria }}
        </div>
        <div *ngIf="property?.quartosEx > 0" class="i-text-sm mr-auto py-2">
          {{ property.quartosEx || 0 }}
          {{ property.quartosLegendaEx }}
        </div>
        <div *ngIf="property?.area > 0" class="i-text-sm mr-auto py-2">
          {{ property?.area }}
          <ng-container *ngIf="!property?.agro">m<sup>2</sup></ng-container>
          <ng-container *ngIf="property?.agro">ha</ng-container>
        </div>
        <div *ngIf="property?.vagas > 0" class="i-text-sm mr-auto mt-auto py-2">
          {{ property?.vagas || 0 }}
          VAGA{{ property?.vagas === 1 ? '' : 'S' }}
        </div>
        <a
          [routerLink]="property?.internalUrl"
          data-toggle="tooltip"
          title="IMÓVEL VISUALIZADO"
          aria-label="IMÓVEL VISUALIZADO">
          <i class="iconify" data-icon="mdi-eye-outline"></i>
        </a>
      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-4 my-auto">
      <div class="i-propertie py-2">
        <div class="i-text">{{ property?.categoria | uppercase }}</div>
        <div class="i-subtitle">{{ property?.bairro | uppercase }}</div>
      </div>
      <div class="i-info py-2">
        <p class="i-text" [innerHTML]="descriptionResume | html"></p>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-3 text-right my-auto">
      <div class="i-value py-2">
        <div *ngIf="property?.podeMostrarValorAnterior || false" class="i-text">
          <s>{{ property?.valorAnterior | R$: 0 }}</s>
        </div>
        <div lass="i-subtitle">
          <span
            *ngIf="property?.statusEx === Status.Rent"
            data-toggle="tooltip"
            data-html="true"
            [title]="property?.valorAluguelDetalhado">
            <i class="iconify" data-icon="mdi-information-outline"></i>
          </span>
          <ng-container *ngIf="property?.valor > 0; else semValor">
            {{ property?.valor | R$: 0 }}
          </ng-container>
          <ng-template #semValor> Sob consulta </ng-template>
        </div>
      </div>
      <div *ngIf="showMoreLessButton" class="mb-auto my-2">
        <button class="i-btn" (click)="onToggleListDetail(BoxType.Detail)">VER MAIS</button>
      </div>
    </div>
    <div class="i-bd-bottom mx-3"></div>
  </div>
</ng-container>
<!--Box-Detail-->
<ng-container *ngIf="boxType === BoxType.Detail">
  <div class="i-box-detail row mt-3">
    <div class="col-12 col-lg-6">
      <app-box-carousel [property]="property" [targetBlank]="targetBlank" [numberOfPhotos]="numberOfPhotos">
      </app-box-carousel>
      <div
        *ngIf="showBroker && corretor"
        class="i-broker d-none d-lg-flex flex-row align-items-center justify-content-center p-5">
        <div *ngIf="corretor?.fotoUrl">
          <img defaultImage="assets/img/img_loading.gif" [lazyLoad]="corretor?.fotoUrl" [alt]="corretor?.nome" />
        </div>
        <div class="mx-auto text-center">
          <div class="i-subtitle pt-3 pb-2">{{ corretor?.nome }}</div>
          <div class="i-phone">
            <ng-container *ngIf="property?.agro && (corretor?.celular || corretor?.fone); else detailPhone">
              <a href="tel:{{ formatPhone(corretor) }}">
                {{ corretor?.celular || corretor?.fone }}
              </a>
            </ng-container>
            <ng-template #detailPhone>
              <a href="tel:+555132762040">51 3276 2040</a>
            </ng-template>
          </div>
          <div class="pt-2">
            <!-- <a
              href="https://api.whatsapp.com/send?phone=55{{ formatPhone(property?.corretor) }}&text="
              class="mt-2"
              target="_blank"
              rel="noopener"
              title="Whatsapp do corretor"
              (click)="onGaEvent('click', 'whatsapp', property?.corretor?.celular)">
              <div class="badge badge-whatsapp p-3">
                CONTATE O ESPECIALISTA <i class="iconify" data-icon="mdi-whatsapp" style="font-size: 150%"></i>
              </div>
            </a> -->
            <!-- <app-corretor-whatsapp
              [propertyCode]="property?.codigo"
              [broker]="property?.corretor"></app-corretor-whatsapp> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 pt-0 pt-lg-5">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="i-propertie py-3">
            <div class="i-text" [ngClass]="{ 'shine shine-line': !property?.id }">
              {{ property?.categoria | uppercase }}
            </div>
            <div class="i-subtitle" [ngClass]="{ 'shine shine-line': !property?.id }">
              {{ property?.bairro | uppercase }}
            </div>
          </div>
          <div class="d-none d-lg-flex flex-column flex-lg-row py-3">
            <div class="i-text-sm mr-auto mt-auto py-2">COD {{ property?.codigo }}</div>
            <div *ngIf="property?.agro" class="i-text-sm mr-auto py-2">
              {{ property.categoria }}
            </div>
            <div *ngIf="property?.quartosEx > 0" class="i-text-sm mr-auto py-2">
              {{ property.quartosEx || 0 }} {{ property.quartosLegendaEx }}
            </div>
            <div *ngIf="property?.area > 0" class="i-text-sm mr-auto py-2">
              {{ property?.area }}
              <ng-container *ngIf="!property?.agro">m<sup>2</sup></ng-container>
              <ng-container *ngIf="property?.agro">ha</ng-container>
            </div>
            <div *ngIf="property?.vagas > 0" class="i-text-sm mr-auto mt-auto py-2">
              {{ property?.vagas || 0 }}
              VAGA{{ property?.vagas === 1 ? '' : 'S' }}
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 text-right">
          <div class="i-value py-3" [ngClass]="{ 'shine shine-line': !property?.id }">
            <div *ngIf="property?.podeMostrarValorAnterior || false" class="i-text">
              <s>{{ property?.valorAnterior | R$: 0 }}</s>
            </div>
            <div class="i-subtitle">
              <span
                *ngIf="property?.statusEx === Status.Rent"
                data-toggle="tooltip"
                data-html="true"
                [title]="property?.valorAluguelDetalhado">
                <i class="iconify" data-icon="mdi-information-outline"></i>
              </span>
              <ng-container *ngIf="property?.valor > 0; else semValor">
                {{ property?.valor | R$: 0 }}
              </ng-container>
              <ng-template #semValor> Sob consulta </ng-template>
            </div>
          </div>
          <div *ngIf="showMoreLessButton" class="mb-auto my-2">
            <button class="i-btn p-3 w-100" (click)="onToggleListDetail(BoxType.List)">VER MENOS</button>
          </div>
          <div class="d-flex d-lg-none flex-column flex-lg-row py-3">
            <div class="i-text-sm mr-auto mt-auto py-2">COD {{ property?.codigo }}</div>
            <div *ngIf="property?.agro" class="i-text-sm mr-auto py-2">
              {{ property.categoria }}
            </div>
            <div *ngIf="property?.quartosEx > 0" class="i-text-sm mr-auto py-2">
              {{ property.quartosEx || 0 }} {{ property.quartosLegendaEx }}
            </div>
            <div *ngIf="property?.area > 0" class="i-text-sm mr-auto py-2">
              {{ property?.area }}
              <ng-container *ngIf="!property?.agro">m<sup>2</sup></ng-container>
              <ng-container *ngIf="property?.agro">ha</ng-container>
            </div>
            <div *ngIf="property?.vagas > 0" class="i-text-sm mr-auto mt-auto py-2">
              {{ property?.vagas || 0 }}
              VAGA{{ property?.vagas === 1 ? '' : 'S' }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="i-description" [ngClass]="{ 'shine h-100': !property?.id }">
            <div class="i-subtitle py-4">{{ property?.titulo }}</div>
            <p class="i-text" [innerHTML]="property?.descricaoWeb | html"></p>
          </div>
          <div *ngIf="property?.incorp">
            <div
              *ngFor="let infra of property?.infraestruturas | slice: 0 : 7"
              class="badge badge-pill badge-private mr-2 mt-2">
              {{ infra?.nome }}
            </div>
            <ng-container *ngIf="(property?.infraestruturas?.length ?? 0) > 8"> ... </ng-container>
          </div>
          <div *ngIf="property?.id" class="d-flex flex-row py-4">
            <!-- <button class="i-btn col-6 w-50 mr-2">FALE COM O CORRETOR</button> -->
            <button
              *ngIf="property?.isBuilding && property?.incorp"
              class="i-btn col col-md-6 w-50"
              [routerLink]="['/imoveis/status', property?.getStatus(), 'cidade', property?.cidadeSlug]"
              [queryParams]="{ em: property?.empreendimentoSlug }">
              VER IMÓVEIS
            </button>
            <button
              *ngIf="property?.codigoAgencia !== '8'"
              class="i-btn col col-md-6 w-50"
              [routerLink]="property?.internalUrl">
              PÁGINA DO IMÓVEL
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="i-bd-bottom mx-3 mb-3"></div>
  </div>
</ng-container>
<!--Box-Expaned-->
<ng-container *ngIf="boxType === BoxType.Expanded">
  <div class="i-box-expanded">
    <app-box-carousel
      [property]="property"
      [disableDrag]="true"
      [targetBlank]="targetBlank"
      [numberOfPhotos]="numberOfPhotos"></app-box-carousel>

    <div class="i-text-comparable">
      <!-- Categoria -->
      <!-- <div class="pt-2">
                <div class="i-text">{{property?.categoria | uppercase}}</div>
            </div> -->
      <div class="d-flex justify-content-between pt-2">
        <div class="i-text">{{ property?.categoria | uppercase }}</div>
        <div class="i-text-sm">COD {{ property?.codigo }}</div>
      </div>

      <!-- Bairro -->
      <!-- <div class="pt-0">
                {{property?.bairro || 'Bairro indisponível'}}
            </div> -->
      <div class="i-subtitle d-flex justify-content-between">
        {{ property?.bairro | uppercase }}
        <a
          [routerLink]="property?.internalUrl"
          data-toggle="tooltip"
          title="IMÓVEL VISUALIZADO"
          aria-label="IMÓVEL VISUALIZADO">
          <i class="iconify" data-icon="mdi-eye-outline"></i>
        </a>
      </div>

      <!-- Value -->
      <hr />
      <div class="text-center px-1">
        <div class="i-text d-block">
          <s *ngIf="property?.podeMostrarValorAnterior || false"> {{ property?.valorAnterior | R$: 0 }}</s>
          &nbsp;
        </div>
        <div class="i-subtitle">
          <ng-container *ngIf="property?.valor > 0; else semValor">
            {{ property?.valor | R$: 0 }}
          </ng-container>
          <ng-template #semValor> Sob consulta </ng-template>
        </div>
      </div>

      <!-- Value -->
      <ng-container *ngIf="property?.agro">
        <hr />
        <div class="text-center px-1">
          {{ property.categoria }}
        </div>
      </ng-container>

      <!-- Area -->
      <hr />
      <div class="text-center px-1">
        <ng-container *ngIf="property?.area; else nd">
          {{ property?.area }}
          <ng-container *ngIf="!property?.agro">m<sup>2</sup></ng-container>
          <ng-container *ngIf="property?.agro">ha</ng-container>
        </ng-container>
      </div>

      <!-- Valor por m2 -->
      <hr />
      <div class="text-center px-1">
        <div class="i-subtitle">
          <ng-container *ngIf="property?.valor && property?.area; else nd">
            {{ property?.valor / property?.area | R$: 0 }}
            <ng-container *ngIf="!property?.agro">/m<sup>2</sup></ng-container>
            <ng-container *ngIf="property?.agro">/ha</ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Quartos -->
      <hr />
      <div class="text-center px-1">
        <ng-container *ngIf="property?.quartosEx; else nd">
          {{ property?.quartosEx || 0 }}
          {{ property?.quartosLegendaEx }}
        </ng-container>
      </div>

      <!-- Banheiros -->
      <hr />
      <div class="text-center px-1">
        <ng-container *ngIf="property?.totalBanheiros; else nd">
          {{ property?.totalBanheiros || 0 }}
          {{ 'BANHEIRO' | plural: property?.totalBanheiros | uppercase }}
        </ng-container>
        <ng-template #nd> Indisponível </ng-template>
      </div>

      <!-- Vagas -->
      <hr />
      <div class="text-center px-1">
        <ng-container *ngIf="property?.vagas; else nd">
          {{ property?.vagas || 0 }}
          VAGA{{ property?.vagas === 1 ? '' : 'S' }}
        </ng-container>
      </div>

      <!-- Bairro -->
      <hr />
      <div class="text-center px-1">
        {{ property?.bairro || 'Bairro indisponível' }}
      </div>

      <!-- Tags -->
      <div *ngIf="tags?.length > 0" class="text-left">
        <hr />

        <div *ngFor="let tag of tags" class="caract-{{ tag.toLocaleLowerCase().replace(' ', '-') }}">
          <ng-container *ngIf="check(tag); else noTagCheck">
            <!-- text-dark -->
            <i class="iconify mr-1" data-icon="mdi-check-box-outline"></i>
            <!-- class="text-dark" -->
            <span>{{ tag }}</span>
          </ng-container>
          <ng-template #noTagCheck>
            <i class="iconify mr-1" data-icon="mdi-square-outline"></i>
            <span>{{ tag }}</span>
          </ng-template>
        </div>

        <!-- <div *ngFor="let carac of property?.caracteristicas" class="badge badge-pill badge-private mr-2 mt-2">
                {{carac?.nome}}</div>

            <div *ngFor="let infra of property?.infraestruturas" class="badge badge-pill badge-private mr-2 mt-2">
                {{infra?.nome}}</div> -->
      </div>

      <!-- Mapa -->
      <hr />
      <div class="i-map w-100 text-center overflow-auto">
        <img
          defaultImage="assets/img/img_loading.gif"
          [lazyLoad]="property?.mapaUrl"
          errorImage="assets/img/mapa_placeholder.png" />
      </div>
    </div>
  </div>
</ng-container>
